import DefaultPlayer from './components/video/millicast/players/DefaultPlayer';
import { OVERLAY_HANDSET } from './components/video/videoComponents';
import styles from './styles/App.module.scss';

function App() {

  const urlParams = new URLSearchParams(window.location.search);



  return (
    <div className={styles.App}>
      <DefaultPlayer
        overlay={OVERLAY_HANDSET}
        config={{
          streamAccountId: urlParams.get('streamAccountId') || process.env.REACT_APP_STREAM_ACCOUNT_ID || null,
          streamName: urlParams.get('streamName') || process.env.REACT_APP_STREAM_NAME || null
        }}
      />
    </div>
  );
}

export default App;
