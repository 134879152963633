import React, { useMemo, useRef, useState, useEffect } from 'react';
import MillicastOverlayHandset from '../MillicastOverlayHandset';
import styles from '../../video.module.scss';
import { OVERLAY_HANDSET } from '../../videoComponents';
import useMillicastStream from '../useMillicastStream';
import Spinner from '../../../spinner/Spinner';

const DefaultPlayer = ({ config, overlay }) => {
  const [muted, setMuted] = useState(false);
  const videoRef = useRef(null);
  const [playerReady, connectionState] = useMillicastStream(config, videoRef);
  const [initialInteractionDone, setInitialInteractionDone] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const showSpinner = useMemo(() => !playerReady || connectionState !== 'connected', [playerReady, connectionState]);

  const noStreamDefined = useMemo(() => !config.streamAccountId || !config.streamName, [config]);

  const showOverlay = useMemo(() => {
    console.log('show overlay props', { playerReady, connectionState, muted, initialInteractionDone, videoPlaying });
    return !initialInteractionDone && playerReady && connectionState === 'connected' && (muted || !videoPlaying);
  }, [playerReady, connectionState, muted, initialInteractionDone, videoPlaying]);

  useEffect(() => {
    console.log('playerReady', playerReady);
    console.log('connectionState', connectionState);
  }, [playerReady, connectionState]);

  useEffect(() => {
    if (!videoRef?.current) return;

    const videoPlayingInterval = setInterval(() => {
      console.log('video playing', videoRef?.current.currentTime);
      if (videoRef?.current.currentTime > 0 && !videoRef?.current.paused) {
        setVideoPlaying(true);
      } else {
        setVideoPlaying(false);
      }
    }, 1000);

    return () => clearInterval(videoPlayingInterval);
  }, [videoRef]);

  const clickHandler = e => {
    setMuted(false);
    videoRef?.current.play();
    setInitialInteractionDone(true);
  };

  if (noStreamDefined) {
    return <span className={styles.notificationMessageError}>Invalid stream url</span>
  }

  return (
    <>
      <video
        className={styles.videoContainer}
        ref={videoRef}
        autoPlay
        muted={muted}
        playsInline
      />
      {showSpinner && <Spinner colour="#ffffff" fillScreen={true} />}
      {
        overlay === OVERLAY_HANDSET &&
        <MillicastOverlayHandset
          muted={muted}
          videoReady={playerReady}
          clickHandler={clickHandler}
          showOverlay={showOverlay}
          connectionState={connectionState}
          config={config}
        />
      }
    </>
  )
}

export default DefaultPlayer;