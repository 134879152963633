import React, { useEffect, useState, useMemo } from 'react';
import { Director, View } from '@millicast/sdk';

const USE_INTERNAL_AUTO_RECONNECT = false;
const BASE_RECONNECT_INTERVAL = 1000;
const MAX_RECONNECTION_INTERVAL = 10000;
let reconnectionInterval = BASE_RECONNECT_INTERVAL;

const useMillicastStream = (config, videoEl) => {
  const [view, setView] = useState(null);
  const [playerReady, setPlayerReady] = useState(false);
  const [connectionState, setConnectionState] = useState(null);

  // useVisibilityChange({onShow: () => { 
  //   if(isMobileSafari && semver.lt(osVersion, '15.2.0')) {
  //     window.location.reload();
  //   }
  // }});

  useEffect(() => {
    if (!config) return;
    if (!config.streamAccountId || !config.streamName) return;

    const videoPropsReady = config !== null && videoEl.current !== null;
    if (view === null && videoPropsReady) {
      const tokenGenerator = () => Director.getSubscriber(config);
      console.log({ videoEl })
      setView(new View(config.streamName, tokenGenerator, videoEl.current, USE_INTERNAL_AUTO_RECONNECT));
    }
  }, [view, config, videoEl]);

  useEffect(() => {
    if (!view) return;

    view.on('connectionStateChange', connectionState => {
      console.log('connectionStateChange:', connectionState);
      setConnectionState(connectionState);
    });

    attemptConnection();
  }, [view]);

  const attemptConnection = () => {
    connect().then(() => {
      reconnectionInterval = BASE_RECONNECT_INTERVAL;
      // Adam: these don't seem to do anything...
      // Jamie: They don't get re-emitted by View.signalling, so would have to subscribe directly.
      view.signaling.on('wsConnectionError', e => console.log('wsConnectionError:', e));
      view.signaling.on('wsConnectionClose', e => console.log('wsConnectionClose:', e));
      view.signaling.on('wsConnectionSuccess', e => console.log('wsConnectionSuccess:', e));
    }).catch(err => console.warn('connection failed', err)) // have to wait for connection, as view.signalling doesn't exist until view.connect has been called.
  }

  const connect = async () => {
    try {
      console.log('connecting...')
      await view.connect();
      setPlayerReady(true);
    } catch (e) {
      // console.log('Connection failed, handle error:', e);
      setConnectionState('failed');
      throw new Error('failed');
    }
  }

  const nextReconnectInterval = (interval) => {
    return interval < MAX_RECONNECTION_INTERVAL ? interval + 500 : interval
  }

  // RETRY on 'failed' event
  useEffect(() => {
    if (connectionState === 'failed') {
      setConnectionState('retrying');
      reconnectionInterval = nextReconnectInterval(reconnectionInterval);
      console.log('retrying in ', reconnectionInterval / 1000, 'seconds')
      setTimeout(() => { attemptConnection() }, reconnectionInterval);
    }
  }, [connectionState]);

  return [playerReady, connectionState];
}

export default useMillicastStream;