import React, { useState, useEffect, useMemo } from 'react';
import styles from '../video.module.scss';
import classNames from 'classnames';
import Spinner from '@piingltd/piing-ui/dist/shared/components/Spinner/Spinner';
import { TapToUnmute } from '../videoComponents';

const MillicastOverlayHandset = ({ videoReady, clickHandler, showOverlay = true, connectionState = null, config = null }) => {
  const [showPlayButton, setShowPlayButton] = useState(false);
  const error = useMemo(() => connectionState !== 'connected', [connectionState]);

  return (
    <div className={classNames(
      styles.controlsHarness,
      showOverlay ? styles.blur : styles.noBlur
    )}>
      {
        showOverlay &&
        <>
          {
            (config.streamAccountId === 'null' || config.streamName === 'null') &&
            <div className={styles.noConfig}>
              No stream name or account ID found
            </div>
          }
          {
            (videoReady && showOverlay) &&
            <TapToUnmute
              showPlayButton={showPlayButton && showOverlay}
              setShowPlayButton={setShowPlayButton}
              clickHandler={clickHandler}
            />
          }
          {
            (error || !videoReady) && <Spinner />
          }
        </>
      }
    </div>
  )
}

export default MillicastOverlayHandset;