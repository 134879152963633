import React, { useEffect } from 'react';
import styles from './video.module.scss';
import { IoVolumeMuteSharp, IoVolumeHighSharp } from 'react-icons/io5';
import { useEventListener } from '@piingltd/piing-ui/dist/utils/hooks';

export const OVERLAY_BIGSCREEN = 0;
export const OVERLAY_HANDSET = 1;

export const ClickToToggleMute = ({ muted }) => (
  <div className={styles.tapToToggleMute}>
    {
      muted === true &&
      <IoVolumeMuteSharp className={styles.unMuteSymbolBig} />
    }
    {
      muted === false &&
      <IoVolumeHighSharp className={styles.unMuteSymbolBig} />
    }
  </div>
);

export const TapToUnmute = ({ showPlayButton, setShowPlayButton, clickHandler }) => {
  useEffect(() => {
    setTimeout(() => setShowPlayButton(true), 1000);
  }, []);

  useEventListener('mousedown', (e) => {
    clickHandler(e);
  }, document);

  return (
    <div className={styles.tapToUnmute} style={{
      opacity: showPlayButton ? 1 : 0,
      transform: `scale(${showPlayButton ? 1 : 0.9})`
    }}>
      {'Play'}
    </div>
  )
}