import React, { useState, useEffect } from 'react';
import styles from './spinner.module.scss';

const Spinner = ({ colour = 'white', fillScreen = false, delayShowSeconds = 0 }) => {
  const [show, setShow] = useState(0);
  const spin = <div className={styles.spinner} style={{ borderTopColor: `${colour}`, opacity: show }} />;

  useEffect(() => {
    setTimeout(() => setShow(1), delayShowSeconds * 1000);
  }, [delayShowSeconds]);

  if (fillScreen) {
    return (
      <div className={styles.spinnerHarness}>
        {spin}
      </div>
    )
  }
  else return spin;
};

export default Spinner;